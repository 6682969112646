<template>
  <b-card
    tag="article"
  >
    <template #header>
      <h6 class="mb-0">#{{cycle.cycle_index}} Cycle</h6>
    </template>
    <b-card-text class="d-flex flex-col gap-2">
      <table>
        <tbody>
          <tr>
            <td class="row-fit">
              <span class="mr-2">Date</span>
            </td>
            <td>
              <date-picker
                class="date-picker w-full"
                v-model="cycle.cycle_date"
                format="DD.MM.YYYY HH:mm"
                type="datetime"
                valueType="YYYY-MM-DD HH:mm:SS"
                :clearable="false"
                :lang="datepickerLocale"
                :disabled="!canEdit"
              />
            </td>
          </tr>

          <tr>
            <td class="row-fit">
              <span class="text-nowrap mr-2">Weight (Start)</span>
            </td>
            <td>
              <div>
                <b-input-group
                  size="sm"
                  append="g"
                >
                  <b-form-input
                    v-model="cycle.cycle_start_weight"
                    autocomplete="off"
                    size="sm"
                    trim
                    :disabled="!canEdit"
                  ></b-form-input>
                </b-input-group>
              </div>
            </td>
          </tr>

          <template v-for="(state,state_index) in cycle.states">
            <tr
              v-if="showState(state)"
              :key="`cycle_${cycle.cycle_index}_period_${state.period_id}`"
            >
              <td class="row-fit">
                <span class="text-nowrap mr-2">{{ state.period_description }}</span>
              </td>
              <td>
                <div class="d-flex flex-col gap-2">
                  <b-form-select
                    v-model="state.issue_id"
                    :options="periodIssues[state.period_id]"
                    text-field="issue_description"
                    value-field="id"
                    size="sm"
                    class="w-auto"
                    :disabled="!canEditState(state, state_index) || !canEdit"
                  ></b-form-select>

                  <issue-option
                    v-model="cycle.states[state_index]"
                    :issues="issues"
                  />
                </div>
              </td>
            </tr>
          </template>

          <tr>
            <td class="row-fit">
              <span class="text-nowrap mr-2">Weight (End)</span>
            </td>
            <td>
              <div>
                <b-input-group
                  size="sm"
                  append="g"
                >
                  <b-form-input
                    v-model="cycle.cycle_end_weight"
                    autocomplete="off"
                    size="sm"
                    trim
                    :disabled="!canEdit"
                  ></b-form-input>
                </b-input-group>
              </div>
            </td>
          </tr>
        </tbody>
      </table>

      <div
        v-if="cycle.cycle_index === this.lastCycle?.cycle_index"
        class="d-flex justify-content-between"
      >
        <div>
          <a
            v-if="cycle.cycle_end_period !== null"
            href="#"
            class="text-primary"
            @click.prevent="restoreCycle"
          >
            Continue cycle
          </a>
          <a
            v-else-if="canEnd"
            href="#"
            class="text-primary"
            @click.prevent="endCycle"
          >
            End cycle early
          </a>
        </div>

        <div>
          <a
            href="#"
            class="text-danger"
            @click.prevent="deleteCycle"
          >
            Delete cycle
          </a>
        </div>
      </div>
    </b-card-text>
  </b-card>
</template>

<script>
import {
  datepickerLocale,
} from '@/helpers';

const IssueOption = () => import('@/components/burn_samples/IssueOption.vue');

export default {
  name: 'SampleCycle',
  components: {
    IssueOption,
  },
  props: {
    sample: Object,
    cycle: Object,
    lastCycle: Object,
    periodIssues: Object,
    periods: Array,
    issues: Array,
  },
  computed: {
    datepickerLocale() {
      return datepickerLocale;
    },
    canEdit() {
      return this.lastCycle ? this.cycle.cycle_index === this.lastCycle.cycle_index : true;
    },
    canEnd() {
      if (this.cycle.states[0].issue_id === null) return false;
      const lastPeriodId = this.cycle.cycle_index === this.sample.sample_cycles ? 6 : 5;
      const lastCycleState = this.cycle.states.find((item) => item.period_id === lastPeriodId);
      if (lastCycleState.issue_id !== null) return false;
      return true;
    },
    endPeriod() {
      if (!this.cycle.cycle_end_period) return null;
      return this.periods.find((period) => period.id === this.cycle.cycle_end_period);
    },
  },
  methods: {
    canEditState(state, index) {
      if (index === 0) return true;
      if (this.cycle.states[index - 1].issue_id === 1) return true;
      return false;
    },
    deleteCycle() {
      this.$emit('delete', this.cycle);
    },
    endCycle() {
      const lastState = this.cycle.states.findLast((state) => state.issue_id !== null);
      this.cycle.cycle_end_period = lastState.period_id;
    },
    restoreCycle() {
      this.cycle.cycle_end_period = null;
    },
    showState(state) {
      const disabledPeriods = (
        this.endPeriod
          ? this.periods
            .filter((period) => period.period_order > this.endPeriod.period_order)
            .map((period) => period.id)
          : null
      );
      if (disabledPeriods && disabledPeriods.includes(state.period_id)) {
        return false;
      }
      if (state.period_id === 6 && this.cycle.cycle_index !== this.sample.sample_cycles) {
        return false;
      }
      return true;
    },
  },
  data() {
    return {};
  },
};
</script>

<style lang="scss" scoped>
</style>
